import React, { useState, useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import { navigate, Link } from "gatsby";

import Settings from "src/stores/Settings";

import { icons, mediaquery, spacing, breakpoints } from "src/styles/variables";
import {
  Category as CategoryShape,
  Subcategory as SubcategoryShape,
} from "./types";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import Close from "src/atoms/Vectors/Standard/Close";
import Subcategories from "./Subcategories";
import FlavorDetails from "./FlavorDetails";
import ListItem from "src/molecules/ProductCategories/ListItem";
import CategoryPush from "src/molecules/ProductCategories/Push";
import { ORIGINS as FLAVOR_PAGE_ORIGINS } from "src/templates/FlavorPage";
import getLocalizedSlug from "src/utils/getLocalizedSlug";
import TouchProvider from "src/stores/Touch";
import {
  trackCategoryPush,
  trackProductChange,
  trackSubcategory,
} from "./trackEvents";

export const ANIMATIONS = Object.freeze({
  staticup: "static,up", // when navigating from another flavor page
  staticright: "static,right", // when navigating from categories page
  rightright: "right,right", // when navigating from anywhere else (default)
  leftleft: "right,left",
  staticleft: "left,left",
});
const ANIMATION_DURATION_STEP01 = 450;
const ANIMATION_DELAY_STEP02 = 50;
const ANIMATION_DURATION_STEP02 = 400;
const OUT_ANIMATION_DURATION = 450;

// this page should look identical to:
// the the category list page (with an active category)
// so that the navigation transition between the two is seamless
const Flavor = ({
  flavor,
  animation = ANIMATIONS.rightright,
  ...productBreadcrumbs
}) => {
  const { locale } = useContext(Settings);
  const { closeTitle } = useContext(Settings).translations;

  const [firstRender, setFirstRender] = useState(true);
  const [show, setShow] = useState(true);
  const [backToProductsAnimated, setBackToProductsAnimated] = useState(false);
  const [showDestiniModal, setShowDestiniModal] = useState(false);
  const subcategory = flavor.subcategory;
  const category = subcategory.category;
  const [outAnimation, setOutAnimation] = useState(ANIMATIONS.staticup);
  const [categoryAnimation, flavorAnimation] = (firstRender
    ? animation
    : outAnimation
  ).split(",");
  const [swipeAnimation, setSwipeAnimation] = useState(null);
  const isTouch = useContext(TouchProvider);
  const destiniContainer = useRef(null);
  const flavorAnimationDelay = categoryAnimation !== "static";
  const componentFlavor = flavor;

  useEffect(() => {
    // console.log(
    //   'JSON.parse(window.localStorage.getItem("isSubcatChanged"))',
    //   JSON.parse(window.localStorage.getItem("isSubcatChanged"))
    // );
    if (JSON.parse(window.localStorage.getItem("isSubcatChanged")) === true) {
      // console.log(
      //   'JSON.parse(window.localStorage.getItem("isSubcatChanged")) inside',
      //   JSON.parse(window.localStorage.getItem("isSubcatChanged"))
      // );
      window.localStorage.setItem("isSubcatChanged", false);
      trackSubcategory(subcategory.name, subcategory.flavors[0].label);
    }
  }, [subcategory]);

  const animateThenNavigate = (
    slug,
    { category, swipeAnimation, keepScrollPosition = false } = {}
  ) => {
    setOutAnimation(
      category === FLAVOR_PAGE_ORIGINS.subcategory
        ? ANIMATIONS.staticup
        : ANIMATIONS.staticright
    );

    if (swipeAnimation) {
      setSwipeAnimation(swipeAnimation);
    }

    const { navigationHistory } = (history && history.state) || {};
    if (navigationHistory) {
      navigationHistory[navigationHistory.length - 1] = slug;
    }

    setFirstRender(false);
    setShow(false);
    setTimeout(() => {
      navigate(slug, {
        state: {
          from: category,
          isFromFlavor: true,
          navigationHistory,
          keepScrollPosition,
        },
      });
      setShow(true);
    }, ANIMATION_DURATION_STEP02);
  };

  const navigateBackAnimated = (path, navigationHistory) => {
    setBackToProductsAnimated(true);
    setTimeout(() => {
      navigate(path, {
        state: {
          navigationHistory,
        },
      });
    }, OUT_ANIMATION_DURATION);
  };

  const navigateBack = () => {
    const { navigationHistory } = (history && history.state) || {};

    if (!navigationHistory || navigationHistory.length === 1) {
      const to = getLocalizedSlug(category.categoryList.slug, locale);
      return navigate(to, { state: { navigationHistory: [to] } });
    }

    navigationHistory.splice(-1, 1);
    const to = navigationHistory[navigationHistory.length - 1];

    navigateBackAnimated(to, navigationHistory);
  };

  const flavorAnimationDirection =
    swipeAnimation && typeof swipeAnimation === "string"
      ? swipeAnimation
      : flavorAnimation;

  const onSelectFlavor = (flavor, transitionAnimation) => {
    const isSwipe =
      transitionAnimation && typeof transitionAnimation === "string";
    // console.log("flavor.label", componentFlavor);
    trackProductChange(
      flavor.label,
      componentFlavor.categoryPush.category.title
    );

    animateThenNavigate(isTouch ? `${flavor.slug}#p` : flavor.slug, {
      category: isSwipe
        ? `${FLAVOR_PAGE_ORIGINS.sameCategory}-${transitionAnimation}`
        : FLAVOR_PAGE_ORIGINS.sameCategory,
      swipeAnimation: transitionAnimation,
      keepScrollPosition: true,
    });
  };

  const showDestiniWidget = () => {
    if (destini) {
      setShowDestiniModal(true);

      document.body.style.overflow = "hidden";

      setTimeout(() => {
        destini.init("destini-modal-content");
        destini.loadWidget(flavor.destiniAssociationCode);
      }, 0);

      setTimeout(() => {
        const iframe = destiniContainer.current?.firstChild;
        iframe.setAttribute("scrolling", "auto");
      }, 0);
    }
  };

  const closeDestiniWidget = () => {
    document.body.style.overflow = "unset";
    setShowDestiniModal(false);
  };

  return (
    <Outer>
      <div style={{ display: "none" }}>
        {/* encourage prefetching with hidden Links */}
        {category.subcategories.map((subcategory, key) => (
          <Link key={key} to={subcategory.slug} />
        ))}
        {subcategory.flavors.map((flavor, key) => (
          <Link key={key} to={flavor.slug} />
        ))}
        {!!flavor.categoryPush && (
          <Link to={flavor.categoryPush.category.slug} />
        )}
      </div>

      <Left backToProductsAnimated={backToProductsAnimated}>
        <Category direction={categoryAnimation}>
          <ListItemStyled isPush {...category} />
        </Category>
      </Left>
      <Right backToProductsAnimated={backToProductsAnimated}>
        <CloseLinkOuter>
          <CloseLink onClick={navigateBack} title={closeTitle}>
            <FloatingButton size={SIZES.medium}>
              <Close size={icons.m} />
            </FloatingButton>
          </CloseLink>
        </CloseLinkOuter>

        <span id="p" />
        {category.subcategories.length > 1 && (
          <SubcategoriesStyled
            items={category.subcategories}
            active={subcategory}
            onChange={(subcat) => {
              window.localStorage.setItem("isSubcatChanged", true);
              animateThenNavigate(subcat.slug, {
                category: FLAVOR_PAGE_ORIGINS.subcategory,
                keepScrollPosition: true,
              });
            }}
          />
        )}

        <FlavorDetailsStyled
          {...flavor}
          flavors={subcategory.flavors}
          onSelectFlavor={onSelectFlavor}
          show={show}
          productBreadcrumbs={productBreadcrumbs}
          delay={flavorAnimationDelay}
          direction={flavorAnimationDirection}
          showDestiniWidget={showDestiniWidget}
        />
        {!!flavor.categoryPush && (
          <CategoryPush
            {...flavor.categoryPush}
            href={flavor.categoryPush.category.slug}
            onClick={(e) => {
              e.preventDefault();
              trackCategoryPush(
                flavor.categoryPush.category.title,
                flavor.flavorTitle
              );
              animateThenNavigate(flavor.categoryPush.category.slug);
            }}
          />
        )}
      </Right>
      {showDestiniModal && (
        <DestiniModal onClick={closeDestiniWidget}>
          <DestiniModalContentWrapper>
            <CloseLinkOuter>
              <CloseLink onClick={closeDestiniWidget} role="presentation">
                <FloatingButton size={SIZES.medium}>
                  <Close size={icons.m} />
                </FloatingButton>
              </CloseLink>
            </CloseLinkOuter>
            <DestiniModalContent
              ref={destiniContainer}
              id="destini-modal-content"
            />
          </DestiniModalContentWrapper>
        </DestiniModal>
      )}
    </Outer>
  );
};

Flavor.propTypes = {
  animation: PropTypes.oneOf(Object.values(ANIMATIONS)),
  productBreadcrumbs: PropTypes.object,
  flavor: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    label: PropTypes.string,
    destiniAssociationCode: PropTypes.string,
    adimoTouchpointId: PropTypes.string,
    adimoWidget: PropTypes.string,
    categoryPush: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      image: PropTypes.object,
      category: PropTypes.shape(Object.assign({}, CategoryShape)),
    }),
    subcategory: PropTypes.shape(
      Object.assign({}, SubcategoryShape, {
        flavors: FlavorDetails.propTypes.flavors,
        category: PropTypes.shape(
          Object.assign({}, CategoryShape, {
            slug: PropTypes.string, // not required here
            categoryList: PropTypes.shape({
              slug: PropTypes.string.isRequired,
              categories: PropTypes.arrayOf(PropTypes.shape(CategoryShape)),
            }).isRequired,
            subcategories: PropTypes.arrayOf(PropTypes.shape(SubcategoryShape)),
          })
        ),
      })
    ),
    flavorTitle: PropTypes.string,
  }),
};

const Outer = styled.div`
  ${mediaquery.lg(css`
    display: flex;
  `)}
`;
const Left = styled.div`
  ${mediaquery.lg(css`
    z-index: 1;
    width: 66.66666vw;
    height: 100vh;
    position: sticky;
    top: 0;
    ${({ backToProductsAnimated }) =>
      backToProductsAnimated &&
      css`
        opacity: 0;
        transition: opacity ${OUT_ANIMATION_DURATION / 1000}s
          cubic-bezier(0, 0, 0.12, 1);
      `}
  `)}
`;
const Right = styled.div`
  padding-top: ${spacing.default.xl};

  ${mediaquery.sm(css`
    min-width: 375px;
    max-width: 50%;
    margin: 0 auto;
  `)}

  ${mediaquery.lg(css`
    z-index: 0;
    width: 33.33333vw;

    ${({ backToProductsAnimated }) =>
      backToProductsAnimated &&
      css`
        opacity: 0;
        transform: translateX(100%);
        transition: all ${OUT_ANIMATION_DURATION / 1000}s
          cubic-bezier(0, 0, 0.12, 1);
      `}
  `)}
`;

const DestiniModal = styled.div`
  position: fixed;
  z-index: 2;

  min-width: 100vw;
  min-height: 100vh;

  top: 0%;
  left: 0;

  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(16px);

  display: flex;

  align-items: center;
  justify-content: center;
`;

const DestiniModalContentWrapper = styled.div`
  position: relative;

  background-color: white;
  width: 100%;
  /* 770px = Destini iframe height */
  height: 770px;
  border-radius: 0;
  overflow: auto;

  ${mediaquery.md(css`
    height: 770px;
    margin: ${spacing.inset.sm};
    border-radius: ${spacing.default.lg};
    max-width: ${breakpoints.md}rem;
    width: 100%;
  `)};
`;

const DestiniModalContent = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
`;

const Category = styled.div`
  position: relative;
  &:before {
    content: "";
    display: block;
    /* per design; when x=375, y=435, so y=x*1.16 */
    padding-top: 116%;

    ${mediaquery.sm(css`
      /* per design; when x=768, y=341, so y=x*0.44 */
      padding-top: 44%;
    `)}
  }

  ${mediaquery.lg(css`
    width: 100%;
    height: 100%;

    &:before {
      display: none;
    }

    animation: ${({ direction }) =>
        direction === "right"
          ? enterRight
          : direction === "up"
          ? enterUp
          : "none"}
      forwards ${ANIMATION_DURATION_STEP01}ms cubic-bezier(0, 0, 0.12, 1);
  `)}
`;

const CloseLinkOuter = styled.div`
  position: sticky;
  top: 10px;
  margin-right: 10px;
  z-index: 1;
`;

const CloseLink = styled.button`
  position: absolute;
  right: 10px;
`;

const ListItemStyled = styled(ListItem)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const SubcategoriesStyled = styled(Subcategories)`
  margin: ${spacing.stack.xl};
  padding: 0 ${spacing.default.sm};
  flex-wrap: wrap;
`;

const enterUp = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
const exitUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
`;
const enterRight = keyframes`
  0% {
    transform: translateX(-195px);
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const enterLeft = keyframes`
  0% {
    transform: translateX(195px);
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const exitRight = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-195px);
    opacity: 0;
  }
`;

const exitLeft = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(195px);
    opacity: 0;
  }
`;

const FlavorDetailsStyled = styled(FlavorDetails)`
  ${mediaquery.lg(css`
    padding: ${spacing.default.xl};
    padding-top: 0;
  `)}

  animation: ${({ show, direction }) =>
    ({
      right: { true: enterRight, false: exitRight },
      up: { true: enterUp, false: exitUp },
      left: { true: enterLeft, false: exitRight },
      swipeLeft: { true: enterLeft, false: exitRight },
      swipeRight: { true: enterRight, false: exitLeft },
    }[direction][show])}
      both ${ANIMATION_DURATION_STEP02}ms cubic-bezier(0, 0, 0.12, 1)
      ${({ delay }) => (delay ? ANIMATION_DELAY_STEP02 : 0)}ms;
`;

export default Flavor;
